import React, {Fragment, useEffect, useState, useRef} from 'react';
import {useForm, Controller} from 'react-hook-form';
import InputMask from 'react-input-mask';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './NewAppointment.css'
import {
    Paper,
    Box,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    Button,
    RadioGroup,
    FormLabel,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Backdrop,
    CircularProgress,
    FormHelperText
} from '@material-ui/core';
import logo from "../../images/logo.png"
import appointmentService from "../services/appointmentService";
import AppointmentMembers from "./AppointmentMembers";
import SuccessRegistration from "./SuccessRegistration";
import {useHistory} from "react-router";
import refugeeCategories from '../services/refugeeCategories';

const NewRefugeeAppointment = () => {
    const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY
    const history = useHistory();
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [appDate, setAppDate] = useState('')
    const [appTime, setAppTime] = useState('')
    const [checkStatus, setCheckStatus] = useState('loading')

    useEffect(() => {
        if (typeof history.location.state === 'undefined') {
            history.push("/register")
        }
        history.replace(history.location.state, undefined);
    }, [history])

    document.title = 'Реєстрація внутрішньо переміщеної особи'
    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .required('поле обовʼязкове')
            .max(50, 'не більше 50 символів'),
        last_name: Yup.string()
            .required('поле обовʼязкове')
            .max(50, 'не більше 50 символів'),
        fathers_name: Yup.string()
            .required('поле обовʼязкове')
            .max(50, 'не більше 50 символів'),
        category: Yup.string()
            .required('поле обовʼязкове'),
        vpo_number: Yup.string()
            .required('поле обовʼязкове')
            .length(15, 'некоректний номер довідки'),
        vat_number: Yup.string()
            .required('поле обовʼязкове')
            .length(10, 'повинно бути 10 цифр'),
        phone: Yup.string()
            .required('поле обовʼязкове')
            .length(19, 'некоректний номер телефону'),
        agree: Yup.bool().oneOf([true], 'необхідно надати згоду'),
        family: Yup.lazy(() => Yup.array().of(Yup.object({
            first_name: Yup.string()
                .required('поле обовʼязкове')
                .max(50, 'не більше 50 символів'),
            last_name: Yup.string()
                .required('поле обовʼязкове')
                .max(50, 'не більше 50 символів'),
            fathers_name: Yup.string()
                .required('поле обовʼязкове')
                .max(50, 'не більше 50 символів'),
            vpo_number: Yup.string()
                .required('поле обовʼязкове')
                .max(50, 'не більше 50 символів'),
        })))
    });

    const onSubmit = async data => {
        const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
        console.log(JSON.stringify(data, null, 2));
        setLoading(true)
        appointmentService.createAppointment({
            first_name: data.first_name.trim(),
            last_name: data.last_name.trim(),
            fathers_name: data.fathers_name.trim(),
            category: data.category.trim(),
            vpo_number: data.vpo_number.trim(),
            vat_number: data.vat_number.trim(),
            phone: data.phone.trim().replace(/[ ()-]/g, ""),
            family: data.family,
            captcha_token: token
        }).then(resp => {
            setAppDate(resp.date_public)
            setAppTime(resp.time_public)
            setSuccess(true)
            setLoading(false)
            console.log("APP created", resp)
            window.scrollTo({top: 0, left: 0, behavior: "smooth"})
        }).catch(err => {
            const error = err?.response?.data?.error || '';
            handleError(error);
            setOpen(true);
            setLoading(false);
        })
    };

    const handleError = (error) => {
        switch (error) {
            case "already_registered":
                setErrorMessage('Ви вже зареєстровані на отримання допомоги');
                break;
            case "took_help":
                setErrorMessage('Ви вже отримували допомогу в нашому фонді');
                break;
            case "no_slots":
                setErrorMessage('Нажаль немає вільних місць на отримання допомоги');
                break;
            case "invalid_captcha":
                setErrorMessage('Перевірка капчі не пройдена. Будь ласка, спробуйте ще раз');
                break;
            default:
                setErrorMessage('Можливо ви ввели некоректні дані або сервіс не працює');
                break;
        }
    };

    useEffect(() => {
        appointmentService.getAppointmentStatus()
            .then(res => {
                console.log('res', res)
                if (res.success) {
                    setCheckStatus('good')
                } else {
                    setCheckStatus('blocked')
                }
            }).catch(() => {
                setCheckStatus('unavailable')
            }
        )
    }, [])

    useEffect(() => {
        // Load the recaptcha script
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);
            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }
            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`, function () {
            console.log("Script loaded!");
        });
    }, []);

    // const defaultValues = {
    //     first_name: "Serhii",
    //     last_name: "Shpota",
    //     fathers_name: "Volodymyrovych",
    //     vpo_number: "5544-5454545455",
    //     vat_number: "1234567890",
    //     phone: "+38234234323",
    //     familys: [
    //         {
    //             first_name: "BABA",
    //             last_name: "Uera",
    //             fathers_name: "Ivanovna",
    //             vpo_number: "2232323-2323"
    //         }
    //     ],
    // }

    const categories = refugeeCategories.getForSelect();

    const defaultValues = {
        first_name: "",
        last_name: "",
        fathers_name: "",
        vpo_number: "",
        vat_number: "",
        phone: "",
        agree: false,
        family: [],
    }

    const handleClose = () => {
        setOpen(false)
    }

    const {
        register,
        control,
        handleSubmit,
        getValues,
        setValue,
        formState: {errors}
    } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema)
    });

    if (checkStatus === 'vacations') {
        return (
            <Box py={5}>
                <Grid container alignItems="center" justifyContent="center"
                      style={{textAlign: "center", flexDirection: "column"}}>
                    <Box>
                        <img src={logo} style={{width: "70%", marginBottom: "20px"}}/>
                    </Box>
                    <Typography variant="body" component="p" style={{fontSize: "20px", padding: "0 10px"}}>
                        Любі друзі, наш штаб не працює з 28 грудня до 5 січня:)<br/>
                        Запис до електроної черги на 6 січня відкриється 3 січня.<br/><br/>
                        Зустрінемося в наступному році, усім гарних свят!
                    </Typography>
                </Grid>
            </Box>
        )
    }

    if (checkStatus === 'loading') {
        return (
            <Backdrop style={{color: '#fff', backgroundColor: '#8d8d8d'}} open={true}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        )
    }

    if (checkStatus === 'unavailable') {
        return (
            <Box py={5}>
                <Grid container alignItems="center" justifyContent="center">
                    <Typography color="error" variant="h5">
                        Сервіс не працює!
                    </Typography>
                </Grid>
            </Box>
        )
    }

    if (checkStatus === 'blocked') {
        return (
            <Box py={5}>
                <Grid container alignItems="center" justifyContent="center"
                      style={{textAlign: "center", flexDirection: "column"}}>
                    <Box>
                        <img src={logo} style={{width: "70%", marginBottom: "20px"}}/>
                    </Box>
                    <Typography variant="body" component="p" style={{fontSize: "16px", padding: "0 20px", textAlign: "justify"}}>
                        Нажаль, наразі немає вільних місць на отримання допомоги<br/><br/>
                        Новий запис відкриється завтра. <br/><br/>
                        <strong>Черга відкривається з понеділка по п'ятницю о 8:00.</strong> <br/><br/>

                        Допомога видається згідно місця реєстрації. За однією адресою - одне місце в черзі.<br/><br/>
                        Гуманітарна допомога  для внутрішньо  переміщенних осіб (ВПО) по категоріях:<br/><br/>
                        - ОДИНОРАЗОВО всі ВПО, зареєстровані в Одесі чи Одеській області після  24.02.2022 (оригінали документів: довідка ВПО, паспорт,  ідентифікаційний код чи особисту  ДІЮ на кожного члена сім'ї) <br/><br/>
                        - ВПО старше 75 років ОДИН РАЗ НА 3 МІСЯЦІ зареєстровані в Одесі чи Одеській області після  24.02.2022 (оригінали документів: довідка ВПО, паспорт,  ідентифікаційний код чи особиста  ДІЯ на кожного члена сім'ї) <br/><br/>
                        - ВПО із ПЕРШОЮ групою інвалідності ОДИН РАЗ НА 3 МІСЯЦІ зареєстровані в Одесі чи Одеській області після  24.02.2022 (оригінали документів: довідка ВПО, паспорт,  ідентифікаційний код, свідоцтво про інвалідність/чи особиста  ДІЯ)<br/><br/>

                        Комплектація допомоги згідно наявності.<br/><br/>
                        <strong>ДОПОМОГА НАДАЄТЬСЯ ЛИШЕ ПРИ НАЯВНОСТІ ІНДИВІДУАЛЬНОГО ПОДАТКОВОГО НОМЕРА (КОДА).</strong><br/><br/>
                        <strong>КОПІЇ,  СКРІНШОТИ,  ФОТО - НЕ ПІДХОДЯТЬ.</strong><br/><br/>
                    </Typography>
                </Grid>
            </Box>
        )
    }

    return (
        <Fragment>
            {
                success ?
                    <SuccessRegistration date={appDate} time={appTime}/> :
                    <Box px={3} py={2}>
                        <Backdrop style={{color: '#fff', backgroundColor: '#8d8d8d', opacity: "0.4", zIndex: "9999999"}}
                                  open={loading}>
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                        <Typography variant="h6" align="center" margin="dense" style={{fontWeight: "bold"}}>
                            Реєстрація внутрішньо переміщеної особи
                        </Typography>
                        <br/>

                        <Typography variant="body1" align="justify" margin="dense">
                        ДОПОМОГА НАДАЄТЬСЯ ЛИШЕ ПРИ НАЯВНОСТІ ІНДИВІДУАЛЬНОГО ПОДАТКОВОГО НОМЕРА (КОДА)<br/><br/>

                        Категорії надання допомоги  для внутрішньо  переміщенних осіб (ВПО):<br/><br/>
                        - ОДИНОРАЗОВО всі ВПО (що переїхали до Одеської області з інших областей), зареєстровані в Одесі чи Одеській області після  24.02.2022 (оригінали документів: довідка ВПО, паспорт,  ідентифікаційний код чи особисту  ДІЮ на кожного члена сім'ї) - продукти харчування, засоби гігієни. Один раз на місяць  - одягу.<br/><br/>
                        - ВПО старше 75 років ОДИН РАЗ НА 3 МІСЯЦІ зареєстровані в Одесі чи Одеській області після  24.02.2022 (оригінали документів: довідка ВПО, паспорт,  ідентифікаційний код чи особиста  ДІЯ на кожного члена сім'ї)<br/><br/> 
                        - ВПО із ПЕРШОЮ групою інвалідності ОДИН РАЗ НА 3 МІСЯЦІ зареєстровані в Одесі чи Одеській області після  24.02.2022 (оригінали документів: довідка ВПО, паспорт,  ідентифікаційний код, свідоцтво про інвалідність/чи особиста  ДІЯ).<br/><br/>

                        Комплектація допомоги згідно наявності.<br/><br/>

                        КОПІЇ,  СКРІНШОТИ,  ФОТО - НЕ ПІДХОДЯТЬ. <br/><br/>

                        Підгузки для дорослих видаються ЗА ПОТРЕБОЮ лише лежачим хворими із довідкою від лікаря (терміном не більше 6 місяців); хворим яким показане користування підгузками (із випискою від лікаря); інвалідам 2 та 1 груп.<br/><br/>
                        Будь ласка внесіть свої дані та дані Вашоі родини у форму
                        </Typography>
                        <br/>

                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Не вдалося завершити реєстрацію
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" color="error" className="blink-error">
                                    {errorMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{justifyContent: "center", marginBottom: "10px"}}>
                                <Button onClick={handleClose} autoFocus color="primary" variant="contained">
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="last_name"
                                    name="last_name"
                                    label="Прізвище"
                                    fullWidth
                                    margin="dense"
                                    {...register('last_name')}
                                    error={!!errors.last_name}
                                    helperText={errors.last_name?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="first_name"
                                    name="first_name"
                                    label="Імʼя"
                                    fullWidth
                                    margin="dense"
                                    {...register('first_name')}
                                    error={!!errors.first_name}
                                    helperText={errors.first_name?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="fathers_name"
                                    name="fathers_name"
                                    label="По-батькові"
                                    fullWidth
                                    margin="dense"
                                    {...register('fathers_name')}
                                    error={!!errors.fathers_name}
                                    helperText={errors.fathers_name?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} style={{marginTop: "5px"}}>
                                <FormControl
                                    fullWidth
                                    required
                                    {...register('category')}
                                    error={!!errors.category}
                                >
                                    <InputLabel id="category">Категорія</InputLabel>
                                    <Controller name="category"
                                                control={control}
                                                render={({field}) => (
                                                    <Select {...field}>
                                                        {categories.map(category => (
                                                            <MenuItem key={category.value}
                                                                      value={category.value}>{category.text}</MenuItem>
                                                        ))}
                                                    </Select>
                                                )}/>
                                    <FormHelperText>{errors.category?.message}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="vpo_number"
                                    render={({field}) => (
                                        <InputMask
                                            mask="5999-9999999999"
                                            maskChar=""
                                            onChange={(e) => field.onChange(e.target.value)}
                                            value={field.value}
                                        >
                                            {() => (
                                                <TextField
                                                    required
                                                    id="vpo_number"
                                                    name="vpo_number"
                                                    type="tel"
                                                    label="Номер довідки ВПО"
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{maxLength: 20}}
                                                    error={!!errors.vpo_number}
                                                    helperText={errors.vpo_number?.message}
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="vat_number"
                                    render={({field}) => (
                                        <InputMask
                                            mask="9999999999"
                                            maskChar=""
                                            onChange={(e) => field.onChange(e.target.value)}
                                            value={field.value}
                                        >
                                            {() => (
                                                <TextField
                                                    required
                                                    id="vat_number"
                                                    name="vat_number"
                                                    label="ІПН"
                                                    type="tel"
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{maxLength: 10}}
                                                    error={!!errors.vat_number}
                                                    helperText={errors.vat_number?.message}
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="phone"
                                    render={({field}) => (
                                        <InputMask
                                            mask="+38 (999) 999-99-99"
                                            maskChar=""
                                            onChange={(e) => field.onChange(e.target.value)}
                                            value={field.value}
                                        >
                                            {() => (
                                                <TextField
                                                    required
                                                    id="phone"
                                                    name="phone"
                                                    type="tel"
                                                    label="Номер телефону"
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{maxLength: 19}}
                                                    error={!!errors.phone}
                                                    helperText={errors.phone?.message}
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box my={2}>
                                    <Typography>
                                        Члени родини:
                                    </Typography>
                                </Box>
                                <AppointmentMembers
                                    {...{control, register, defaultValues, getValues, setValue, errors}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt={1}>
                                    <FormControl error={!!errors.agree?.message}>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name="agree"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({field: {value, ref, ...field}}) => (
                                                        <Checkbox
                                                            {...field}
                                                            inputRef={ref}
                                                            checked={!!value}
                                                            color="primary"
                                                            size={"medium"}
                                                        />
                                                    )}
                                                />
                                            }
                                            label="Згоджуюсь на обробку персональних даних"
                                            labelPlacement="end"
                                        />
                                        <FormHelperText style={{margin: 0}}>{errors.agree?.message}</FormHelperText>
                                    </FormControl>
                                </Box>

                            </Grid>
                        </Grid>

                        <Box mt={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handleSubmit(onSubmit)}
                            >
                                Записатися
                            </Button>
                        </Box>
                    </Box>
            }
        </Fragment>
    )
}

export default NewRefugeeAppointment