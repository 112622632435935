class LocalCategories extends Map {
    getForSelect() {
        return Array.from(this.entries()).map(([value, text]) => ({ value, text }));
    }

    getNameByKey(key) {
        return this.get(key) ?? 'Помилка категорії';
    }
}

const localCategories = new LocalCategories([
    ["old", "75+"],
    ["disabled", "Інвалід 1 або 2 групи"],
    ["mother", "Мати-одиначка"],
    ["family", "Сім'я загиблого воїна"]
]);

export default localCategories;