import React, {Fragment, useEffect, useState} from "react";
import * as Yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Redirect, useLocation, withRouter} from "react-router-dom";
import {
    Backdrop,
    Box, Button, Checkbox,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, FormControlLabel, FormHelperText,
    Grid, InputLabel, MenuItem, Select, TextField,
    Typography
} from "@material-ui/core";
import logo from "../../images/logo.png";
import SuccessRegistration from "./SuccessRegistrationLocal";
import localAppointmentService from "../services/localAppointmentService";
import localCategories from "../services/localCategories";
import {useHistory} from "react-router";
import InputMask from "react-input-mask";


const NewLocalAppointment = () => {
    const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY
    const history = useHistory();
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [appDate, setAppDate] = useState('')
    const [appTime, setAppTime] = useState('')
    const [checkStatus, setCheckStatus] = useState('loading')
    const [returnToMain, setReturnToMain] = useState(false)

    useEffect(() => {
        if (typeof history.location.state === 'undefined') {
            history.push('/register')
        }
        history.replace(history.location.state, undefined);
    }, [history])

    document.title = 'Реєстрація одесита'
    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('поле обовʼязкове').max(50, 'не більше 50 символів'),
        last_name: Yup.string().required('поле обовʼязкове').max(50, 'не більше 50 символів'),
        fathers_name: Yup.string().required('поле обовʼязкове').max(50, 'не більше 50 символів'),
        category: Yup.string().required('поле обовʼязкове'),
        vat_number: Yup.string().required('поле обовʼязкове').length(10, 'повинно бути 10 цифр'),
        phone: Yup.string().required('поле обовʼязкове').length(19, 'некоректний номер телефону'),
        agree: Yup.bool().oneOf([true], 'необхідно надати згоду'),
    });

    const onSubmit = async (data) => {
        // Prevent submission if already loading
        if (loading) return;
        
        try {
            // Set loading state immediately to prevent double submissions
            setLoading(true);
            
            // Get reCAPTCHA token
            const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' });
            
            // Send request to backend
            const resp = await localAppointmentService.createAppointment({
                first_name: data.first_name.trim(),
                last_name: data.last_name.trim(),
                fathers_name: data.fathers_name.trim(),
                vat_number: data.vat_number.trim(),
                phone: data.phone.trim().replace(/[ ()-]/g, ""),
                category: data.category.trim(),
                captcha_token: token
            });
            
            // Handle successful response
            setAppDate(resp.date_public);
            setAppTime(resp.time_public);
            setSuccess(true);
            window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        } catch (err) {
            // Handle error response
            const error = err?.response?.data?.error || '';
            handleErrorMessage(error, err?.response?.data);
            setOpen(true);
        } finally {
            // Always reset loading state after request completes
            setLoading(false);
        }
    };

    const handleErrorMessage = (error, responseData) => {
        switch (error) {
            case "already_registered":
                setErrorMessage('Ви вже зареєстровані на отримання допомоги')
                break
            case "took_help":
                setErrorMessage('Ви вже отримували допомогу в нашому фонді')
                break
            case "local_took_help":
                const daysSinceHelp = responseData?.days_since_help || ''
                const delta = responseData?.delta || ''
                const errMessage = daysSinceHelp !== '' && delta !== '' ?
                    `Ви вже отримували допомогу в нашому фонді ${daysSinceHelp} днів тому. Фонд видає допомогу один раз на ${delta} днів. Спробуйте пізніше` :
                    'Ви вже отримували допомогу в нашому фонді'
                setErrorMessage(errMessage)
                break
            case "no_slots":
                setErrorMessage('Нажаль немає вільних місць на отримання допомоги')
                setReturnToMain(true)
                reset(defaultValues)
                break
            case "invalid_captcha":
                setErrorMessage('Перевірка капчі не пройдена. Будь ласка, спробуйте ще раз')
                break
            default:
                setErrorMessage('Можливо ви ввели некоректні дані або сервіс не працює')
                break
        }
    };

    useEffect(() => {
        if (checkStatus == 'vacations') {
            return
        }
        localAppointmentService.getAppointmentStatus()
            .then(res => {
                console.log('res', res)
                if (res.success) {
                    setCheckStatus('good')
                } else {
                    setCheckStatus('blocked')
                }
            }).catch(() => {
                setCheckStatus('unavailable')
            }
        )
    }, [])

    // const defaultValues = {
    //     first_name: "Serhii",
    //     last_name: "Shpota",
    //     fathers_name: "Volodymyrovych",
    //     vat_number: "12345678",
    //     category: "",
    //     phone: "12344545",
    // }

    const categories = localCategories.getForSelect();

    const defaultValues = {
        first_name: "",
        last_name: "",
        fathers_name: "",
        category: "",
        vat_number: "",
        phone: "",
        agree: false,
    }

    const handleClose = () => {
        setOpen(false)
        if (returnToMain) {
            history.push('/register')
        }
    }

    const {
        register,
        control,
        handleSubmit,
        reset,
        formState: {errors}
    } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema)
    });

    if (checkStatus === 'vacations') {
        return (
            <Box py={5}>
                <Grid container alignItems="center" justifyContent="center"
                      style={{textAlign: "center", flexDirection: "column"}}>
                    <Box>
                        <img src={logo} style={{width: "70%", marginBottom: "20px"}}/>
                    </Box>
                    <Typography variant="body" component="p" style={{fontSize: "20px", padding: "0 10px"}}>
                        Любі друзі, наш штаб не працює з 28 грудня до 5 січня:)<br/>
                        Запис до електроної черги на 6 січня відкриється 3 січня.<br/><br/>
                        Зустрінемося в наступному році, усім гарних свят!
                    </Typography>
                </Grid>
            </Box>
        )
    }

    if (checkStatus === 'loading') {
        return (
            <Backdrop style={{color: '#fff', backgroundColor: '#8d8d8d'}} open={true}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        )
    }

    if (checkStatus === 'unavailable') {
        return (
            <Box py={5}>
                <Grid container alignItems="center" justifyContent="center">
                    <Typography color="error" variant="h5">
                        Сервіс не працює!
                    </Typography>
                </Grid>
            </Box>
        )
    }

    if (checkStatus === 'blocked') {
        return (
            <Box py={5}>
                <Grid container alignItems="center" justifyContent="center"
                      style={{textAlign: "center", flexDirection: "column"}}>
                    <Box>
                        <img src={logo} style={{width: "70%", marginBottom: "20px"}}/>
                    </Box>
                    <Typography variant="body" component="p" style={{fontSize: "16px", padding: "0 20px", textAlign: "justify"}}>
                        Нажаль, наразі немає вільних місць на отримання допомоги на найближчі дати.<br/><br/>
                        Завтра відкриється новий запис<br/><br/>
                        <strong>Черга відкривається з понеділка по п'ятницю о 8:00.</strong> <br/><br/>
                        Допомога видається згідно прописки. За одною адресою - одна реєстрація.<br/><br/>
                        <strong>Увага! Змінилися умови видачі допомоги -  одесити по інвалідності отримують допомогу лише із 1 чи 2 групою інвалідності.</strong><br/><br/>
                        Правила реєстрації на допомогу для ОДЕСИТІВ (прописка Одеса чи Одеська область) один раз на три місяці по категоріях: <br/><br/>
                        - особи старше 75 років (оригінали документів: паспорт чи ІD-картка із паперовим додатком про реєстрацію місця проживання (прописку),  ідентифікаційний код) <br/><br/>
                        - особи із інвалідністю 1 чи 2 групи ( оригінали документів: паспорт чи ІD-картка із паперовим додатком про реєстрацію місця проживання (прописку), ідентифікаційний код, свідоцтво про інвалідність)<br/><br/>
                        - матері - одиначки (оригінали документів: паспорт чи ID-картка із паперовим додатком про реєстрацію місця проживання (прописку), ідентифікаційний код матері, свідоцтво про народження дитини до 18 років, витяг із ст. 135 про статус)<br/><br/>
                        - родини загиблих воїнів з 24.02.2024 (батьки, дружина/чоловік, дитина до 18 років (оригінали документів: свідоцтво про смерть, витяг з наказу командира військової частини про виключення війсковослужбовця із списків частини у зв'язку із його смертю чи УБД; документ про підтвердження родинних стосунків із загиблим (свідоцтво про народження,  свідоцтво про шлюб), документи отримувача (паспорт чи ID-картка із паперовим додатком про реєстрацію місця проживання (прописку), ідентифікаційний код, свідоцтво про народження)<br/><br/>                
                        <strong>ДОПОМОГА НАДАЄТЬСЯ ЛИШЕ ПРИ НАЯВНОСТІ ІНДИВІДУАЛЬНОГО ПОДАТКОВОГО НОМЕРА (КОДА).</strong><br/><br/>
                        <strong>КОПІЇ,  СКРІНШОТИ,  ФОТО - НЕ ПІДХОДЯТЬ.</strong>
                    </Typography>
                </Grid>
            </Box>
        )
    }

    return (
        <Fragment>
            {
                success ?
                    <SuccessRegistration date={appDate} time={appTime}/> :
                    <Box px={3} py={2}>
                        <Backdrop style={{color: '#fff', backgroundColor: '#8d8d8d', opacity: "0.4", zIndex: "9999999"}}
                                  open={loading}>
                            <CircularProgress color="inherit"/>
                        </Backdrop>
                        <Typography variant="h6" align="center" margin="dense" style={{fontWeight: "bold"}}>
                            Реєстрація одесита
                        </Typography>
                        <br/>

                        <Typography variant="body1" align="justify" margin="dense">
                        <strong>Увага! Змінилися умови видачі допомоги -  одесити по інвалідності отримують допомогу лише із 1 чи 2 групою інвалідності.</strong><br/><br/>
                            БО БФ "Ранок Відродження" реєструє на допомогу для ОДЕСИТІВ (прописка Одеса чи Одеська область) один раз на три місяці по категоріях: <br/><br/>
                            - особи старше 75 років (оригінали документів: паспорт чи ІD-картка із паперовим додатком про реєстрацію місця проживання (прописку),  ідентифікаційний код)<br/>
                            - особи із інвалідністю 1 чи 2 групи ( оригінали документів: паспорт чи ІD-картка із паперовим додатком про реєстрацію місця проживання (прописку), ідентифікаційний код, свідоцтво про інвалідність)<br/>
                            - матері - одиначки (оригінали документів: паспорт чи ID-картка із паперовим додатком про реєстрацію місця проживання (прописку), ідентифікаційний код матері, свідоцтво про народження дитини, витяг із ст. 135 про статус)<br/>
                            -  родини загиблих воїнів з 24.02.2024 (батьки, дружина/чоловік, дитина до 18 років (оригінали документів: свідоцтво про смерть, витяг з наказу командира військової частини про виключення війсковослужбовця із списків частини у зв'язку із його смертю чи УБД; документ про підтвердження родинних стосунків із загиблим (свідоцтво про народження,  свідоцтво про шлюб), документи отримувача (паспорт чи ID-картка із паперовим додатком про реєстрацію місця проживання (прописку), ідентифікаційний код, свідоцтво про народження)<br/><br/>
                            Гуманітарна допомога видається згідно прописки, незалежно від реального місця проживання. 
                            Якщо два або більше людей прописані за однією адресою, вони отримують набори як одна родина, тобто 1 (один) набір на 2 (двох), 
                            подвійний + одинарний - на 3 (трьох), 2 (два) подвійних - на 4 (чотирьох) та т.д.<br/><br/>
                            Допомога надається у вигляді продуктів харчування тa засобів гігієни ВИКЛЮЧНО за наявністю повного комплекту документів 
                            в оригіналі чи особистої ДІЇ. КОПІЇ, СКРІНШОТИ, ФОТО - НЕ ПІДХОДЯТЬ! <br/><br/>
                            Підгузки для дорослих видаються ЗА ПОТРЕБОЮ лише лежачим хворим із довідкою від лікаря (терміном не більше 6 місяців); 
                            хворим, яким показане користування підгузками (із випискою від лікаря); інвалідам 2 та 1 груп. 
                            Комплектація допомги згідно наявності.<br/><br/>
                            Будь ласка внесіть свої дані у форму
                        </Typography>
                        <br/>

                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                Не вдалося завершити реєстрацію
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" color="error" className="blink-error">
                                    {errorMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions style={{justifyContent: "center", marginBottom: "10px"}}>
                                <Button onClick={handleClose} autoFocus color="primary" variant="contained">
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="last_name"
                                    name="last_name"
                                    label="Прізвище"
                                    fullWidth
                                    margin="dense"
                                    {...register('last_name')}
                                    error={!!errors.last_name}
                                    helperText={errors.last_name?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="first_name"
                                    name="first_name"
                                    label="Імʼя"
                                    fullWidth
                                    margin="dense"
                                    {...register('first_name')}
                                    error={!!errors.first_name}
                                    helperText={errors.first_name?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="fathers_name"
                                    name="fathers_name"
                                    label="По-батькові"
                                    fullWidth
                                    margin="dense"
                                    {...register('fathers_name')}
                                    error={!!errors.fathers_name}
                                    helperText={errors.fathers_name?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} style={{marginTop: "5px"}}>
                                <FormControl
                                    fullWidth
                                    required
                                    {...register('category')}
                                    error={!!errors.category}
                                >
                                    <InputLabel id="category">Категорія</InputLabel>
                                    <Controller name="category"
                                                control={control}
                                                render={({field}) => (
                                                    <Select {...field}>
                                                        {categories.map(category => (
                                                            <MenuItem key={category.value}
                                                                      value={category.value}>{category.text}</MenuItem>
                                                        ))}
                                                    </Select>
                                                )}/>
                                    <FormHelperText>{errors.category?.message}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="vat_number"
                                    render={({field}) => (
                                        <InputMask
                                            mask="9999999999"
                                            maskChar=""
                                            onChange={(e) => field.onChange(e.target.value)}
                                            value={field.value}
                                        >
                                            {() => (
                                                <TextField
                                                    required
                                                    id="vat_number"
                                                    name="vat_number"
                                                    label="ІПН"
                                                    type="tel"
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{maxLength: 10}}
                                                    error={!!errors.vat_number}
                                                    helperText={errors.vat_number?.message}
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Controller
                                    control={control}
                                    defaultValue=""
                                    name="phone"
                                    render={({field}) => (
                                        <InputMask
                                            mask="+38 (999) 999-99-99"
                                            maskChar=""
                                            onChange={(e) => field.onChange(e.target.value)}
                                            value={field.value}
                                        >
                                            {() => (
                                                <TextField
                                                    required
                                                    id="phone"
                                                    name="phone"
                                                    label="Номер телефону"
                                                    type="tel"
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{maxLength: 19}}
                                                    error={!!errors.phone}
                                                    helperText={errors.phone?.message}
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt={1}>
                                    <FormControl error={!!errors.agree?.message}>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name="agree"
                                                    control={control}
                                                    defaultValue={false}
                                                    render={({field: {value, ref, ...field}}) => (
                                                        <Checkbox
                                                            {...field}
                                                            inputRef={ref}
                                                            checked={!!value}
                                                            color="primary"
                                                            size={"medium"}
                                                        />
                                                    )}
                                                />
                                            }
                                            label="Згоджуюсь на обробку персональних даних"
                                            labelPlacement="end"
                                        />
                                        <FormHelperText style={{margin: 0}}>{errors.agree?.message}</FormHelperText>
                                    </FormControl>
                                </Box>

                            </Grid>
                        </Grid>

                        <Box mt={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handleSubmit(onSubmit)}
                            >
                                Записатися
                            </Button>
                        </Box>
                    </Box>
            }
        </Fragment>
    )
}

export default withRouter(NewLocalAppointment)